import { StackActions, useNavigation, useRoute } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedGModeURL } from "@src/ducks/slices/gmode.slice";
import { selectedUserCoins } from "@src/ducks/slices/user.slice";
import React, { useRef } from "react";
import { View } from "react-native";
import { GModeContext } from "./provider";
import "./style.css";
import useStyles from "./styles.css";

const GameView = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const iframeRef = useRef<any>(null);
  const navigation = useNavigation<any>();
  const gameTypeId = route?.params?.gameTypeId;
  const gameLink = useAppSelector(selectedGModeURL);
  const { GOLD } = useAppSelector(selectedUserCoins);
  const { onSetSMdata } = React.useContext(GModeContext);

  React.useEffect(() => {
    const handleMessage = (event: any) => {
      try {
        const iframe: any = document.getElementById("game-iframe"); // Replace with your iframe's ID
        console.log("POST iframe", event);

        if (event.source === iframe.contentWindow) {
          const data = event.data || {};
          console.log("POST MESSAGE", data);

          if(["close_window", "exit_game", "closeGame"].includes(data.event)){
            navigation.goBack();
          }else if(typeof data === "string" && 
            ["close_window", "exit_game", "closeGame", "home.button.confirmed"].includes(data)){
            navigation.goBack();
          }else if(data.action === "HOME"){
            navigation.goBack();
          }else if(data.action === "SHOP"){
            navigation.dispatch(StackActions.replace("BuyShop"));
          }else if(data.message === "app.bank"){
            navigation.dispatch(StackActions.replace("BuyShop"));
          }

          if(data.message === "app.state"){
            if(data.state && data.state?.fullscreen){
              const document: any = window.document;
              const enabled = Boolean(document.fullscreenElement);

              if(enabled){
                document
                  .exitFullscreen()
                  .then(() => console.log("Document Exited from Full screen mode"))
                  .catch((err: any) => console.error(err));
              }else{
                document.body.requestFullscreen();
              }
            }
          }

          if(gameTypeId === 7){
            onSetSMdata(data.data);
          }
        }
      } catch (error) {
        console.log("error", error);
        navigation.goBack();
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [GOLD, gameTypeId]);

  return (
    <View style={styles.v_iframe_wrapper}>
      <iframe
        ref={iframeRef}
        src={gameLink}
        className="iframe"
        width="100%"
        height="100%"
        id="game-iframe"
        frameBorder={0}
        allowFullScreen={[18].includes(gameTypeId)}
        sandbox="allow-scripts allow-same-origin"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      />
    </View>
  );
};

export default React.memo(GameView);
