// DUCKS pattern
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@src/ducks/store";
import _ from "lodash";
import moment from "moment";
import { getSilverGold, updateWalletBalances, userRewardsUpdate } from "utils/filter-helper";

interface UserState {
  userDetails: any;
  palmsPointBalance: number;
  wallets: any[];
}

export const initialState: UserState = {
  userDetails: {},
  palmsPointBalance: 0,
  wallets: [],
} as UserState;

// Slice
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetchUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    updateProfile: (state, action) => {
      state.userDetails["firstName"] = action.payload.firstName;
      state.userDetails["lastName"] = action.payload.lastName;
      state.userDetails["dateOfBirth"] = action.payload.dateOfBirth;
      state.userDetails["userDetail"] = action.payload.userDetail;
    },
    updateWallet: (state, action) => {
      state.wallets = updateWalletBalances(state.wallets, action.payload);
    },
    updatePalmsPointBalance: (state, action) => {
      state.palmsPointBalance = action.payload;
    },
    updateUserRewards: (state, action) => {
      state.userDetails["userRewards"] = userRewardsUpdate(state?.userDetails?.userRewards ?? [], action.payload);
    },
    resetUserDetails: () => {
      return initialState;
    },
  },
});

// Actions
export const userActions = {
  fetchUserDetails: userSlice.actions.fetchUserDetails,
  updateWallet: userSlice.actions.updateWallet,
  updateUserRewards: userSlice.actions.updateUserRewards,
  updateProfile: userSlice.actions.updateProfile,
  resetUserDetails: userSlice.actions.resetUserDetails,
  updatePalmsPointBalance: userSlice.actions.updatePalmsPointBalance,
};

// Selectors
export const selectUserSession = (state: RootState) => state.user.userDetails;
export const selectPalmsPointBalance = (state: RootState) => state.user.palmsPointBalance;
export const selectedUserSession = createSelector(
  (state: RootState) => state.user.userDetails,
  (session) => ({
    userId: session.id,
    token: session.token,
  })
);
export const selectedUserName = createSelector(
  (state: RootState) => state.user.userDetails,
  (session) => ({
    firstName: session.firstName,
    lastName: session.lastName,
  })
);
export const selectedUserToken = createSelector(
  (state: RootState) => state.user.userDetails,
  (session) => session.token,
);
export const selectedUserEmail = createSelector(
  (state: RootState) => state.user.userDetails,
  (session) => session.emailAddress,
);
export const selectedUserUserID= createSelector(
  (state: RootState) => state.user.userDetails,
  (session) => session.id,
);
export const selectedUserCoins = createSelector(
  (state: RootState) => state.user.wallets,
  (state) => getSilverGold(state || []),
);
export const selectedSilverCoins = createSelector(
  (state: any) => state.user.wallets,
  (state) => {
    const silver = _.find(state, (item) => {
      return item.coinType.name === "SILVER";
    });

    return silver?.amount <= 100000;
  }
);
export const selectedUserNewUser = createSelector(
  (state: any) => state.user.userDetails,
  (state) => {
    const userRewards = state?.userRewards ?? [];
    const newUSerReward = userRewards.find(
      (item: any) =>
        item?.rewards?.name === "New User Rewards" &&
        item.status === "CLAIMABLE"
    );

    return newUSerReward;
  }
);
export const selectedUserProfile = createSelector(
  (state: RootState) => state.user.userDetails,
  (session) => ({
    firstName: session.firstName,
    lastName: session.lastName,
    country: session?.userDetail?.country?.name ?? "",
    gender: session?.userDetail?.gender?.name ?? "",
    month: session.dateOfBirth ? moment(session.dateOfBirth).format("MMMM") : "",
    day: session.dateOfBirth ? moment(session.dateOfBirth).format("D") : "",
    year: session.dateOfBirth ? moment(session.dateOfBirth).format("YYYY") : "",
  })
);

// Reducer
export default userSlice.reducer;
