import BuyshopAssets from "./buyhop-assets";
import CongratsAssets from "./congrats-assets";
import DailyBonusAssets from "./daily-bonus-assets";
import HighRollerAssets from "./high-roller-assets";
import HotDealsAssets from "./hot-deals-assets";
import HotDealsButtonAssets from "./hotdeals-button-assets";
import MissionVaultAssets from "./mission-vault-assets";
import StarterPackAssets from "./starter-pack-assets";
import TGIFAssets from "./tgif-assets";
import TierLevelUpAssets from "./tier-level-up-assets";
import WeeklyBoosterAssets from "./weekly-booster-assets";
import WhistleWarriorAssets from "./whistle-warrior-assets";
import MissionRewardAssets from "./mission-reward-assets";
import GoldenBallAssets from "./golden-ball-assets-bgn";
import NewGameTag from "./new-game-tag-assets";
import TopGameTag from "./top-game-tag-assets";

const AnimationImages = {
  ...BuyshopAssets,
  ...TierLevelUpAssets,
  ...HotDealsButtonAssets,
  ...StarterPackAssets,
  ...TGIFAssets,
  ...HotDealsAssets,
  ...HighRollerAssets,
  ...CongratsAssets,
  ...DailyBonusAssets,
  ...WhistleWarriorAssets,
  ...WeeklyBoosterAssets,
  ...MissionVaultAssets,
  ...MissionRewardAssets,
  ...GoldenBallAssets,
  ...NewGameTag,
  ...TopGameTag,
};

// image preloading
export const AnimationAssets = Object.keys(AnimationImages).map((key: string) => AnimationImages[key as keyof typeof AnimationImages]);

export default AnimationImages;
