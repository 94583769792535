import { useLobbyService } from "@src/ducks/hooks";
import React from "react";
import { View } from "react-native";
import Avatars from "screens/avatar";
import Settings from "screens/settings";
import BottomNav from "../bottomnav";
import CurtainDialog from "../curtain";
import Lobby from "../lobby";
import GameCurrencyTag from "../lobby/game-currency-tag";
import SideDrawer from "../side-drawer";
import TopNav from "../topnav";
import Background from "./background";
import HomeProvider from "./HomeProvider";
import useStyles from "./styles.css";

const Home = () => {
  const styles = useStyles();
  const { onLobbyRequest } = useLobbyService();

  React.useEffect(() => {
    onLobbyRequest();
  }, []);

  return (
    <HomeProvider>
      <View style={styles.container}>
        <Background />
        <SideDrawer />
        <TopNav />
        <Lobby />
        <GameCurrencyTag />
        <BottomNav />
        <Settings />
        <Avatars />
        <CurtainDialog />
      </View>
    </HomeProvider>
  );
};

export default React.memo(Home);
