import type {
  StackNavigationOptions,
  StackNavigationProp,
} from "@react-navigation/stack";

export type RootStackParamList = {
  "Splash": undefined;
  "LoginToPalms": undefined;
  "SignInOption": undefined;
  SignIn: undefined;
  SignUp: undefined;
  Forgot: undefined;
  AccountVerification: undefined;
  PalmsSigningVerification: undefined;
  ResetPassword: undefined;
  "privacy-policy": undefined;
  "ErrorPopup": { message: string };

  Home: undefined;
  WelcomeBonus: undefined;
  DailyBonus: undefined;
  EditName: undefined;
  EditProfile: undefined;
  TierLevel: undefined;
  Disclaimer: undefined;
  LeaderBoardInfo: undefined;
  LeaderBoard: undefined;
  BuyShop: undefined;
  ExchangeGold: undefined;
  JadeEgg: undefined;
  Mission: undefined;
  MissionKey: undefined;
  MissionReward: undefined;
  Inbox: undefined;
  PaymentGateway: undefined;
  PaymentConfirm: undefined;
  StarterPack: undefined;
  OpeningSpecial: undefined;
  GameMode: any;
  Settings: undefined;
  Apcopay: undefined;
  MissionRewards: undefined;
  GoldenBall: undefined;
  PremiumPackage: undefined;
  AccessDenied: undefined;
  TierLevelUp: undefined;
  LevelUpCongrats: undefined;
  TermsCondition: undefined;
  PrivacyPolicy: undefined;
  "gold-conversion": undefined;
  "palms-gold-info": undefined;
  "bonus-gold-info": undefined;
  "insufficient-gold": undefined;
  "insufficient-palms-point": undefined;
  "delete-all-inbox": undefined;
  "game-rules": undefined;
  "coming-soon": undefined;
  "how-to-play": undefined;
  "convert-palms-gold-notification": undefined;
  "edit-display-name": undefined;

  WhistleWarrior: undefined;
  HighRoller: undefined;
  HotDeals: undefined;
  TGIF: undefined;
  WeeklyBooster: undefined;
  HotDealsThumbnails: undefined;
};

export type DrawerStackParamList = {
  Dashboard: undefined;
  Account: undefined;
  Transactions: undefined;
  Signup: undefined;
};

export type TabStackParamList = {
  Home: undefined;
  Account: undefined;
  Settings: undefined;
  Signup: undefined;
};

export type NavigationScreenProps<K extends keyof RootStackParamList> =
  StackNavigationProp<RootStackParamList, K>;

export interface RootStackOptionsValue extends StackNavigationOptions {
  cardStyleInterpolator?: any;
}

export interface ForSlideProps {
  current: any;
  next: any;
  inverted: any;
  layouts: any;
}

const FadeInterpolator = ({ current }: ForSlideProps) => {
  return {
    cardStyle: {
      opacity: current.progres,
    },
  };
};

export const TransitionModal: RootStackOptionsValue = {
  headerShown: false,
  gestureEnabled: false,
  cardShadowEnabled: false,
  cardOverlayEnabled: false,
  presentation: "transparentModal",
  cardStyleInterpolator: FadeInterpolator,
};
