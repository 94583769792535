import { CreateResponsiveStyle } from "rn-responsive-styles";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      height: "100%",
      backgroundColor: colors.black,
    },
    t_waiting: {
      fontSize: 16,
      color: colors.white,
      alignSelf: "center",
      position: "absolute",
      fontFamily: "Roboto-Bold",
    },
    v_waiting: {
      zIndex: 4,
      width: "100%",
      height: "100%",
      position: "absolute",
      justifyContent: "center",
      backgroundColor: colors.black,
    },
    // DRAWER COMPONENT
    v_drawer: {
      left: "-5%",
      width: "22%",
      height: "80%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_bg: { width: "100%", height: "100%", position: "absolute" },
    v_buttons: {
      zIndex: 3,
      right: "3%",
      width: "80%",
      bottom: "2%",
      height: "90%",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    i_fortune8: { width: "40%", height: "20%" },
    btn_style: { width: "30%", height: "12%" },
    btn_share: { width: "40%", height: "8%" },

    btn_float: {
      width: 80,
      height: 80,
      zIndex: 11,
      padding: 10,
      backgroundColor: "red",
      borderRadius: 100,
    },

    btn_float_item: {
      width: 80,
      height: 80,
      backgroundColor: "red",
      borderRadius: 100,
    },

    // GAMEVIEW
    v_iframe_wrapper: {
      width: "100%",
      height: "100%",
    },
    liongames: {
      height: "100%",
      width: "40%",
      alignSelf: "center",
    },
  },
);

export default useStyles;
