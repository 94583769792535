import images from "@assets/images";

export default {
  "v": "5.12.2",
  "fr": 30,
  "ip": 32,
  "op": 90,
  "w": 1024,
  "h": 563,
  "nm": "Comp 1",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 4096,
      "h": 4096,
      "u": "",
      "p": images["silver-coin"],
      "e": 0
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 2,
      "nm": "Silver-Coin 1 2.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 40,
              "s": [
                7
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 42,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 67,
              "s": [
                100
              ]
            },
            {
              "t": 70,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 40,
              "s": [
                565,
                454,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                314,
                15,
                0
              ]
            },
            {
              "t": 70,
              "s": [
                248,
                44,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            2048,
            2048,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 40,
              "s": [
                2,
                2,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 52,
              "s": [
                3,
                3,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 65,
              "s": [
                1,
                1,
                100
              ]
            },
            {
              "t": 67,
              "s": [
                2,
                2,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 40,
      "op": 80,
      "st": -10,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 2,
      "nm": "Silver-Coin 1 2.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 42,
              "s": [
                7
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 44,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 69,
              "s": [
                100
              ]
            },
            {
              "t": 72,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 42,
              "s": [
                565,
                454,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                314,
                15,
                0
              ]
            },
            {
              "t": 72,
              "s": [
                248,
                44,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            2048,
            2048,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 42,
              "s": [
                2,
                2,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 54,
              "s": [
                3,
                3,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 67,
              "s": [
                1,
                1,
                100
              ]
            },
            {
              "t": 69,
              "s": [
                2,
                2,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 42,
      "op": 82,
      "st": -8,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 2,
      "nm": "Silver-Coin 1 2.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 44,
              "s": [
                7
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 46,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 71,
              "s": [
                100
              ]
            },
            {
              "t": 74,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 44,
              "s": [
                565,
                454,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                314,
                15,
                0
              ]
            },
            {
              "t": 74,
              "s": [
                248,
                44,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            2048,
            2048,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 44,
              "s": [
                2,
                2,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 56,
              "s": [
                3,
                3,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 69,
              "s": [
                1,
                1,
                100
              ]
            },
            {
              "t": 71,
              "s": [
                2,
                2,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 44,
      "op": 84,
      "st": -6,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 2,
      "nm": "Silver-Coin 1 2.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 46,
              "s": [
                7
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 48,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 73,
              "s": [
                100
              ]
            },
            {
              "t": 76,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 46,
              "s": [
                565,
                454,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                314,
                15,
                0
              ]
            },
            {
              "t": 76,
              "s": [
                248,
                44,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            2048,
            2048,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 46,
              "s": [
                2,
                2,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 58,
              "s": [
                3,
                3,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 71,
              "s": [
                1,
                1,
                100
              ]
            },
            {
              "t": 73,
              "s": [
                2,
                2,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 46,
      "op": 86,
      "st": -4,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 2,
      "nm": "Silver-Coin 1 2.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 48,
              "s": [
                7
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 50,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 75,
              "s": [
                100
              ]
            },
            {
              "t": 78,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 48,
              "s": [
                565,
                454,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                314,
                15,
                0
              ]
            },
            {
              "t": 78,
              "s": [
                248,
                44,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            2048,
            2048,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 48,
              "s": [
                2,
                2,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 60,
              "s": [
                3,
                3,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 73,
              "s": [
                1,
                1,
                100
              ]
            },
            {
              "t": 75,
              "s": [
                2,
                2,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 48,
      "op": 88,
      "st": -2,
      "bm": 0
    }
  ],
  "markers": [],
  "props": {}
};