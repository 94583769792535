import { combineReducers } from "@reduxjs/toolkit";
import auth from "./slices/auth.slice";
import forgot from "./slices/forgot.slice";
import games from "./slices/games.slice";
import gmode from "./slices/gmode.slice";
import lobby from "./slices/lobby.slice";
import settings from "./slices/settings.slice";
import signup from "./slices/signup.slice";
import sound from "./slices/sound.slice";
import tier from "./slices/tier.slice";
import user from "./slices/user.slice";
import socket from "./slices/socket.slice";

const reducer = combineReducers({
  auth,
  user,
  forgot,
  signup,
  lobby,
  settings,
  gmode,
  tier,
  sound,
  games,
  socket
}); // ADD SLICES HERE

export default reducer;
