import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { RootStackOptionsValue, RootStackParamList, TransitionModal } from "./NavigatorTypes";

// REDUCER
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectAuthLoggedIn } from "@src/ducks/slices/auth.slice";

// COMPONENT SCREENS
import SignIn from "@src/screens/auth/login";
import SignInOption from "@src/screens/auth/main";
import PalmsSigningVerification from "@src/screens/auth/palmsbet";
import ResetPassword from "@src/screens/auth/resetpassword";
import AccountVerification from "@src/screens/auth/signup/signup-verification";

import TermsCondition from "@src/screens/terms-privacy/general-terms";
import PrivacyPolicy from "@src/screens/terms-privacy/privacy-policy";
import Disclaimer from "screens/disclaimer";
import EditName from "screens/editname";
import GameMode from "screens/gamemode";
import Settings from "screens/gamemode/settings";
import HomeComponent from "screens/home/main";
import PaymentGateway from "screens/paymentgateway";
import ApcopayResult from "screens/popups/apcopay-result";
import PaymentConfirm from "screens/popups/pop-notice/successful-purchase";
import TierLevel from "screens/tierlevel";
import EditProfile from "screens/updateprofile";


// ANIMATION POP-UPS
import AnimationPopup from "@src/screens/popups";

const RootStack = createStackNavigator<RootStackParamList>();

const RootStackOptions: RootStackOptionsValue = {
  headerShown: false,
  title: "GameZone",
  cardStyle: { backgroundColor: "transparent" },
};

const AppRouting = () => {
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);

  const screenComponents = React.useMemo(() => {
    if (isLoggedIn) {
      return (
        <>
          <RootStack.Screen name="Home" component={HomeComponent} />
          <RootStack.Screen name="GameMode" component={GameMode} />
          <RootStack.Screen name="EditName" component={EditName} options={TransitionModal} />
          <RootStack.Screen name="EditProfile" component={EditProfile} options={TransitionModal} />
          <RootStack.Screen name="Disclaimer" component={Disclaimer} options={TransitionModal} />
          <RootStack.Screen name="TierLevel" component={TierLevel} options={TransitionModal} />
          <RootStack.Screen name="PaymentGateway" component={PaymentGateway} options={TransitionModal} />
          <RootStack.Screen name="PaymentConfirm" component={PaymentConfirm} options={TransitionModal} />
          <RootStack.Screen name="Settings" component={Settings} options={TransitionModal} />
          <RootStack.Screen name="Apcopay" component={ApcopayResult} options={TransitionModal} />

          <RootStack.Screen name="DailyBonus" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="WhistleWarrior" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="HighRoller" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="HotDeals" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="TGIF" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="StarterPack" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="WeeklyBooster" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="WelcomeBonus" component={AnimationPopup} options={TransitionModal}/>
          <RootStack.Screen name="BuyShop" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="MissionKey" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="Inbox" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="JadeEgg" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="HotDealsThumbnails" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="LeaderBoard" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="MissionRewards" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="GoldenBall" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="PremiumPackage" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="AccessDenied" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="TierLevelUp" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="LevelUpCongrats" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="gold-conversion" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="palms-gold-info" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="bonus-gold-info" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="insufficient-gold" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="insufficient-palms-point" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="delete-all-inbox" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="game-rules" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="coming-soon" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="how-to-play" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="convert-palms-gold-notification" component={AnimationPopup} options={TransitionModal} />
          <RootStack.Screen name="edit-display-name" component={AnimationPopup} options={TransitionModal} />

          <RootStack.Screen name="TermsCondition" component={TermsCondition} options={TransitionModal} />
          <RootStack.Screen name="PrivacyPolicy" component={PrivacyPolicy} options={TransitionModal} />
          <RootStack.Screen name="PalmsSigningVerification" component={PalmsSigningVerification} />
        </>
      );
    } else {
      return (
        <>
          <RootStack.Screen name="SignInOption" component={SignInOption} />
          <RootStack.Screen name="SignIn" component={SignIn} />
          <RootStack.Screen name="PalmsSigningVerification" component={PalmsSigningVerification} />
          <RootStack.Screen name="AccountVerification" component={AccountVerification} />
          <RootStack.Screen name="ResetPassword" component={ResetPassword} />
          <RootStack.Screen name="Apcopay" component={ApcopayResult} options={TransitionModal} />
          <RootStack.Screen name="LoginToPalms" component={AnimationPopup} options={TransitionModal} />
        </>
      );
    }
  }, [isLoggedIn]);

  return (
    <RootStack.Navigator screenOptions={RootStackOptions}>
      { screenComponents}
    </RootStack.Navigator>
  );
};

export default AppRouting;
