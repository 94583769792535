import images from "@assets/images";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedGoldAllowStatus } from "@src/ducks/slices/games.slice";
import React from "react";
import { Image } from "react-native";
import useStyles from "./styles.css";

const Background = () => {
  const styles = useStyles();
  const gold = useAppSelector(selectedGoldAllowStatus);

  return (
    <Image
      source={{uri: gold ? images["lobby-gold-bg"] : images["gamezone-bg"]}}
      style={[styles.ib_style]}
      resizeMode="stretch"
    />
  );
};

export default Background;
