import images from "@assets/images";
import { useDimensionContext } from "@src/DimensionProvider";
import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";
import { useLobbyService } from "@src/ducks/hooks";
import { authActions } from "@src/ducks/slices/auth.slice";
import { selectedChangeScreen, selectedMissionClaimed } from "@src/ducks/slices/lobby.slice";
import { ImageButton } from "components/button";
import React from "react";

const Mission = () => {
  const dispatch = useAppDispatch();
  const {onChangeScreen} = useLobbyService();
  const { hudHeight } = useDimensionContext();
  const { screen } = useAppSelector(selectedChangeScreen);
  const isMissionBonusClaimed = useAppSelector(selectedMissionClaimed);

  const onSetErrorMessage = React.useCallback((value: string) => {
    dispatch(authActions.setErrorMessage(value));
  }, [dispatch]);

  const onMission = () => {
    if (isMissionBonusClaimed) {
      onSetErrorMessage("Вече получи наградата си за тази седмица.\n Върни се отново следващата седмица за нови мисии.");
    } else {
      onChangeScreen({ screen: "mission" });
    }
  };

  return (
    <ImageButton
      onPress={onMission}
      pointerEvents={screen === "mission" ? "none" : "auto"}
      source={{uri: images["lobby-btn-mission"]}}
      style={[{width: hudHeight * 1.477, height: hudHeight * 1.408 }]}
    />
  );
};

export default React.memo(Mission);
