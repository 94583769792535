import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import React from "react";
import { Image, View } from "react-native";
import SilverCoins from "./silver-coins";
import useStyles from "./styles.css";

const SilverBar = () => {
  const styles = useStyles();
  const { hudHeight } = React.useContext(DimensionContext);

  return (
    <View style={[styles.container, { width: hudHeight * 1.7262, height: hudHeight * 0.5728} ]}>
      <Image
        resizeMode="stretch"
        style={styles.i_label}
        source={{uri: images["t-palms-silver"]}}
      />
      <Image
        resizeMode="stretch"
        style={styles.silverBar}
        source={{uri: images.silver_bar}}
      />
      <SilverCoins baseHeight={hudHeight} />
    </View>
  );
};

export default SilverBar;
