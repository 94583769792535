import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { useDimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useLobbyService } from "@src/ducks/hooks";
import { selectedUserCoins } from "@src/ducks/slices/user.slice";
import { ImageButton02, ImageButton04 } from "components/button";
import numeral from "numeral";
import React, { useCallback, useMemo } from "react";
import { Image, Text, View } from "react-native";
import Animated, { Extrapolation, interpolate, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import styles from "./styles.css";
import useOrientationListener from "utils/orientation-helper";

function ActionButton() {
  const animated = useSharedValue(0);
  const navigation = useNavigation<any>();
  const { onChangeScreen } = useLobbyService();
  const orientation = useOrientationListener();

  const balance  = useAppSelector(selectedUserCoins);
  const { baseWidth, baseHeight } = useDimensionContext();

  const { screenWidth, screenHeight, circleSize } = useMemo(() => {
    const screenWidth = orientation === "LANDSCAPE" ? baseWidth * 0.1208 : baseWidth * 0.34;
    const screenHeight = screenWidth * 1.25;

    return {
      screenWidth: screenWidth, 
      screenHeight: screenHeight,
      circleSize: screenWidth * 0.3,
    };
  },[baseWidth, baseHeight, orientation]);

  const onAction = useCallback(() => {
    const value = animated.value === 0 ? 1 : 0;
    animated.value = withTiming(value);
  }, [animated.value, screenHeight]);

  const animatedStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      animated.value,
      [0, 1],
      [-(screenHeight * 0.79), 0],
      Extrapolation.CLAMP
    );
    return {
      position: "absolute", 
      width: screenWidth, 
      height: screenHeight,
      transform: [{ translateY }]
    };
  }, [screenWidth, screenHeight]);

  const onPress = (name: string) => {
    switch (name) {
    case "Home":
      onChangeScreen({ screen: "main" });
      navigation.navigate("Home");
      break;
    case "Settings":
      navigation.navigate("Settings");
      break;
    case "Shop":
      navigation.navigate("BuyShop");
      break;
    }
  };

  return (
    <Animated.View style={animatedStyle} >
      <Image style={{width: "100%", height: "100%", position: "absolute"}} source={{uri: images["dropdown-bg"]}} resizeMode="stretch"/>
      <View style={[ styles.v_container, { gap: screenHeight * 0.037 }]}>
        <View style={styles.v_row}>
          <ImageButton04 onPress={() => onPress("Home")}
            source={{uri: images["home-button"]}} 
            style={{width: "45%", height: "100%"}} />
          <ImageButton04 onPress={() => onPress("Settings")}
            source={{uri: images["settings-button"]}} 
            style={{width: "45%", height: "100%"}} />
        </View>
        <ImageButton04 onPress={() => onPress("Shop")}
          source={{uri: images["shop-button"]}} 
          style={{width: "80%", height: "20%"}} />
        <ImageButton02 disabled
          style={{width: "80%", height: "20%"}} >
          <Image style={{width: "100%", height: "100%"}} source={{uri: images["palms-gold-button"]}} resizeMode="stretch"/>
          <Text selectable={false} style={[styles.t_palms_gold, { fontSize: screenWidth * 0.1}]}>{numeral(balance["GOLD"]).format("0,000.00")}</Text>
        </ImageButton02>
      </View>
      <Animated.View
        style={[
          styles.v_circle,
          {
            width: circleSize,
            minHeight: circleSize,
            borderRadius: circleSize / 2,
          },
        ]}
      >
        <ImageButton02
          onPress={onAction}
          style={[
            styles.btn_float,
            {
              width: circleSize * 0.8,
              height: circleSize * 0.8,
              borderRadius: circleSize * 0.8 / 2,
            },
          ]}
        >
          <Image
            style={{ width: circleSize * 0.7, height: circleSize * 0.7 }}
            source={{uri: images["gamezone-logo"]}}
            resizeMode="cover"
          />
        </ImageButton02>
      </Animated.View>
    </Animated.View>
  );
}

export default ActionButton;
