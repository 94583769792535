import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedAvatarTier } from "@src/ducks/slices/lobby.slice";
import { selectedProfileStatus } from "@src/ducks/slices/settings.slice";
import { selectedUserUserID } from "@src/ducks/slices/user.slice";
import { ImageButton } from "components/button";
import React from "react";
import { Image, Text, View } from "react-native";
import colors from "theme/colors";
import { useFetchGet } from "utils/api/hooks/useFetchGet";
import RankDetails from "./rank-details";
import useStyles from "./styles.css";

const Details = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const tier = useAppSelector(selectedAvatarTier);
  const userId = useAppSelector(selectedUserUserID);
  const { loading, data, runRequest } = useFetchGet();
  const { baseWidth } = React.useContext(DimensionContext);
  const hasProfileOpen =  useAppSelector(selectedProfileStatus);

  React.useEffect(() => {
    if(userId && hasProfileOpen){
      runRequest(`/api/v1/leaderboard/score/${userId}`);
    }
  },[userId, hasProfileOpen]);

  return (
    <View style={styles.p_container}>
      <View style={styles.v_center_profile}>
        <Image
          style={styles.i_profile_frame}
          source={{uri: images.profile_frame}}
          resizeMode="stretch"
        />
        <View style={[styles.v_profile_divider, { height: baseWidth * 0.078}]} />
        <View style={styles.v_details}>
          <View
            style={[
              styles.v_player,
              { width: baseWidth * 0.17, height: baseWidth * 0.038 },
            ]} >
            <Image
              style={styles.i_player_bg}
              source={{uri: images.bg_player}}
              resizeMode="stretch"
            />
            <ImageButton
              onPress={() => navigation.push("EditName")}
              style={styles.btn_edit}
              source={images.icon_edit}
            />
            <Text style={[styles.t_player_name, { fontSize: baseWidth * 0.015 }]} >
              {tier.displayName}
            </Text>
          </View>
          <Text style={[styles.t_user_id, { fontSize: baseWidth * 0.012 }]}>
            <Text style={{color: colors.skyblue6}}>User ID:</Text> {userId}</Text>
          <Image
            style={[
              { 
                marginTop: "10%",
                width: "100%", height: "20%" },
            ]}
            source={{uri: images["t-leaderboard-rank"]}}
            resizeMode="stretch"
          />
          <RankDetails loading={loading} user={data || {}} />
        </View>
      </View>
    </View>
  );
};

export default Details;
