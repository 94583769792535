import { useAppSelector } from "@src/ducks/ducksHook";
import useSoundService from "@src/ducks/hooks/useSoundService";
import { selectedUserCoins } from "@src/ducks/slices/user.slice";
import numeral from "numeral";
import React from "react";
import { Text } from "react-native";
import { useCoinContext } from "../../main/CoinProvider";
import useStyles from "./styles.css";

const SilverCoins = ({ baseHeight }: any) => {
  const styles = useStyles();
  const { claimSoundToggle } = useSoundService();
  const { SILVER }  = useAppSelector(selectedUserCoins);
  const [currentSilver, setCurrentSilver] = React.useState(0);
  const { currency, onAdditionalCurrency } = useCoinContext();

  React.useEffect(() => {
    setCurrentSilver(SILVER);
  }, [SILVER]);

  const onIncrease = (amount: number) => {
    const targetBalance = currentSilver + amount;

    const duration = 900; // 3 seconds total
    const steps = 60; // Number of increments in 3 seconds
    const stepAmount = amount / steps; // Amount to increase per step

    const interval = setInterval(() => {
      setCurrentSilver((prev) => {
        if (prev + stepAmount >= targetBalance) {
          clearInterval(interval); // Stop when reaching target balance
          return targetBalance;
        }
        return prev + stepAmount;
      });
    }, duration / steps); // Calculate interval time per step
  };

  React.useEffect(() => {
    if (currency.silver > 0) {
      onIncrease(currency.silver);
      claimSoundToggle();
      onAdditionalCurrency({ silver: 0, gwz: 0, bg: 0});
    }
  }, [currency.silver]);

  return (
    <Text style={[styles.silverText, { fontSize: baseHeight * 0.16}]}
    >{numeral(currentSilver).format("0,000")}</Text>
  );
};

export default SilverCoins;
