import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { useDimensionContext } from "@src/DimensionProvider";
import { ImageButton06 } from "components/button";
import numeral from "numeral";
import React from "react";
import { ActivityIndicator, Image, Text, View } from "react-native";
import CountdownTimer from "./countdown";
import useStyles from "./styles.css";

const RankDetails = ({ loading, user }: any) => {
  const { rank } = user;
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { baseWidth } = useDimensionContext();
  const fontSize = React.useMemo(() => {
    const length = rank?.length;
    if (length >= 3 && length <= 4) return baseWidth * 0.013;
    if (length >= 5 && length <= 6) return baseWidth * 0.0105;
    if (length >= 7 && length <= 8) return baseWidth * 0.008;
    return baseWidth * 0.017; // Default size
  },[rank, baseWidth]);
  
  return (
    <>
      <View style={styles.v_player_container}>
        <Image source={{uri: images["leaderboard-rank-bar"]}} 
          style={{width: "100%", height: "100%", position: "absolute" }} 
          resizeMode="stretch" />
        <View style={styles.v_player_center}>
          <Text style={[styles.t_player_rank, { fontSize: baseWidth * 0.0176}]}>
            {numeral(user.totalScore).format("0,000")}
          </Text>
        </View>
        <View style={styles.v_your_rank}>
          <Image
            style={styles.i_your_rank}
            source={{uri: images.l_your_rank}}
            resizeMode="stretch"
          />
          {loading ? <ActivityIndicator animating color="white" size="small" style={styles.t_rank_level} /> :
            <Text style={[styles.t_rank_level, { fontSize: fontSize }]}>{rank}</Text>
          }
        </View>
      </View>
      <CountdownTimer baseWidth={baseWidth} />
      <ImageButton06
        onPress={() => navigation.push("LeaderBoard")}
        style={[
          styles.btn_edit_profile,
          { width: baseWidth * 0.18, height: baseWidth * 0.053, marginRight: baseWidth * 0.01 },
        ]}
        label="Виж Класацията"
        source={images["btn-big"]}
        labelStyle={{fontSize: baseWidth * 0.013}}
      />
    </>
  );
};

export default React.memo(RankDetails);