// DUCKS pattern
import { createAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@src/ducks/store";
import Categories from "utils/mockdata/categories";

export interface InitialState {
  games: any[];
  gameCategoryId: number | string;
  featuredList: any[];
  isGoldAllow: boolean;
  fetchingGames: boolean;
  fetchGamesCompleted: boolean;
  categoriesAvailability: any[];
}

export const initialState = {
  gameCategoryId: -1,
  games: [] as any,
  isGoldAllow: false,
  featuredList: [] as any,
  fetchingGames: false,
  fetchGamesCompleted: false,
  categoriesAvailability: Categories
} as InitialState;

// Slice
export const gamesSlice = createSlice({
  name: "games",
  initialState,
  reducers: {
    fetchGoldGames: (state, action) => {
      state.fetchingGames = true;
      state.fetchGamesCompleted = false;
      state.isGoldAllow = action.payload;
    },
    
    fetchingGames: (state, action) => {
      state.games = [];
      state.fetchingGames = true;
      state.fetchGamesCompleted = false;
      state.gameCategoryId = action.payload;
    },

    fetchedGames: (state, action) => {
      state.games = action.payload;
      state.fetchingGames = false;
      state.fetchGamesCompleted = true;
    },

    fetchingGamesFailed: (state) => {
      state.fetchingGames = false;
      state.fetchGamesCompleted = true;
    },

    fetchedFeatureGames: (state, action) => {
      state.featuredList = action.payload;
    },

    fetchedCategoryAvailability: (state, action) => {
      state.categoriesAvailability = action.payload;
    },
  },
});

// Actions
export const gamesActions = {
  fetchGoldGames: gamesSlice.actions.fetchGoldGames,
  fetchingGames: gamesSlice.actions.fetchingGames,
  fetchedGames: gamesSlice.actions.fetchedGames,
  fetchingGamesFailed: gamesSlice.actions.fetchingGamesFailed,
  fetchedFeatureGames: gamesSlice.actions.fetchedFeatureGames,
  fetchedCategoryAvailability: gamesSlice.actions.fetchedCategoryAvailability,
  fetchFavouriteGames: createAction(
    `${gamesSlice.name}/fetchFavouriteGames`,
    (params: any) => ({ payload: params })
  ),
};

// Selectors
export const selectedFetchingGames = (state: RootState) => state.games.fetchingGames;
export const selectedFetchGamesCompleted = (state: RootState) => state.games.fetchGamesCompleted;
export const selectedFetchGames = (state: RootState) => state.games.games;
export const selectedGameCategoryID = (state: RootState) => state.games.gameCategoryId;
export const selectedGoldAllowStatus = (state: RootState) => state.games.isGoldAllow;
export const selectedFeatureGames = (state: RootState) => state.games.featuredList;
export const selectedCategoryAvailability = (state: RootState) => state.games.categoriesAvailability;

// Reducer
export default gamesSlice.reducer;
