import images from "@assets/images";
import { PARTNER_ID } from "@env";
import { useNavigation } from "@react-navigation/native";
import { AuthContext } from "@src/AuthProvider";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useLobbyService } from "@src/ducks/hooks";
import { selectedUserUserID } from "@src/ducks/slices/user.slice";
import { ImageButton06 } from "components/button";
import React, { useContext, useEffect, useState } from "react";
import { Image, Text, TextInput, View } from "react-native";
import { useFetchPost } from "utils/api/hooks";
import useStyles from "./styles.css";

const EditName = () => {
  const styles = useStyles();
  const [name, setName] = useState("");
  const navigation = useNavigation<any>();
  const [error, setError] = useState(false);
  const { loading, runRequest } = useFetchPost();
  const { onUpdateAvatarTier } = useLobbyService();
  const userId = useAppSelector(selectedUserUserID);
  const { baseWidth } = useContext(DimensionContext);
  const { onErrorMessage } = useContext(AuthContext);

  const onDone = () => {
    if (name) {
      const tarea_regex = /(http:|https:|ws:|wss:)/;
      if (tarea_regex.test(name.toLowerCase())) {
        setError(true);
      } else {
        runRequest(`/api/v1/user/${userId}/changeusername`, { 
          partnerId: PARTNER_ID,
          username: name 
        }, () => {
          onUpdateAvatarTier({displayName: name});
          navigation.goBack();
        }, (error) => {
          onErrorMessage(error?.message);
        });
      }
    } else {
      navigation.goBack();
    }
  };

  useEffect(() => {
    setError(false);
  }, [name]);

  return (
    <View style={styles.container}>
      <View style={[styles.center_view, { height: baseWidth * 0.08}]}>
        <Image
          style={styles.i_background}
          source={{uri: images.bg_edit_name}}
          resizeMode="stretch"
        />
        <View style={[styles.v_textwrap, error && styles.v_textwrap_error]}>
          <TextInput
            value={name}
            maxLength={15}
            onChangeText={setName}
            placeholder="ВЪВЕДИ ИМЕ"
            style={[styles.ti_username, { fontSize: baseWidth * 0.02}]}
            placeholderTextColor={"white"}
          />
          {error && <Text style={styles.t_error}>Invalid</Text>}
        </View>
        <ImageButton06
          label="ГОТОВО"
          onPress={onDone}
          isLoading={loading}
          style={styles.btn_close}
          labelStyle={{fontSize: baseWidth * 0.012, marginBottom: "5%"}}
          source={images["btn-small"]}
        />
      </View>
    </View>
  );
};

export default EditName;
