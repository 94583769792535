import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";
import { authActions, selectedAuthToken } from "@src/ducks/slices/auth.slice";
import { useCallback, useEffect, useState } from "react";
import { CurrentApi } from "../request";

export type ErrorValue = {
  message: string
}

export type FetchGetProps = {
  loading: boolean;
  data: any;
  error: ErrorValue;
  runRequest: (
    routes: string,
    handleSuccess?: (e: any) => void, 
    handleError?: (e: any) => void) => void;
  runReset: () => void;
}

export const useFetchGet = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState(null);
  const accessToken = useAppSelector(selectedAuthToken);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorValue>({} as ErrorValue);

  const runRequest = useCallback(
    async (routes: string, handleSuccess: (e: any) => void, handleError: (e: any) => void) => {
      try {
        setLoading(true);
        CurrentApi.setToken(accessToken);
        const result = await CurrentApi.get(`${routes}`);

        setData(result);
        setLoading(false);

        if(typeof handleSuccess === "function"){
          handleSuccess(result);
        }
      } catch (err: any) {
        if(err?.status === 401 && err?.error === "Unauthorized"){
          dispatch(authActions.logout());
          dispatch(authActions.setErrorMessage("Session Expired!"));
        }

        const message = err?.error?.message ?? err?.message ?? "Something went wrong";
        setError({message});
        setLoading(false);

        if(typeof handleError === "function"){
          handleError({message});
        }
      }
    },
    [accessToken, dispatch]
  );

  useEffect(() => {
    if(loading){
      // setData(null);
      setError({} as ErrorValue);
    }
  },[loading]);

  const runReset = useCallback(() => {
    setError({} as ErrorValue);
    setData(null);
  },[]);

  return {
    loading,
    data,
    error,
    runRequest,
    runReset,
  } as FetchGetProps;
};
