import { StyleSheet } from "react-native";
import colors from "theme/colors";

const styles = StyleSheet.create({
  v_container: {width: "100%", height: "100%", alignItems: "center", paddingTop: "8%"},
  v_row: {
    width: "80%", height: "25.42%", 
    flexDirection: "row", alignItems: "center", 
    justifyContent: "space-between"
  },
  t_palms_gold: {position: "absolute", fontFamily: "Roboto-Bold", color: "white", alignSelf: "center", top: "15%", marginLeft: "6%"},

  v_circle: {
    zIndex: 1,
    bottom: "-7%",
    borderWidth: 2,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    borderColor: colors.yellow,
    backgroundColor: colors.skyblue3,
  },
  btn_float: {
    width: 40,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 40 / 2,
  },
});

export default styles;
