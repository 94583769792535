import images from "@assets/images";
import { useDimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedGoldAllowStatus } from "@src/ducks/slices/games.slice";
import { selectedChangeScreen } from "@src/ducks/slices/lobby.slice";
import React from "react";
import { Image, StyleSheet } from "react-native";

export default function GameCurrencyTag() {
  const gold = useAppSelector(selectedGoldAllowStatus);
  const { hudHeight } = useDimensionContext();
  const { screen } = useAppSelector(selectedChangeScreen);

  if(screen === "mission"){
    return null;
  }

  return (
    <Image 
      source={{uri: images[gold ? "gold-game-tag" : "silver-game-tag"]}} 
      style={[styles.container, { bottom: hudHeight } , gold && { width: "14%"}]} resizeMode="stretch" />
  );
}

const styles = StyleSheet.create({
  container: {
    width: "15%", height: "5.5%",
    bottom: "20%", left: "27.5%", 
    position: "absolute"
  }
});
