import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import React from "react";
import { Image, View } from "react-native";
import CoinCounter from "./coin-counter";
import PalmsDropdown from "./dropdown";
import useStyles from "./styles.css";

const PalmPoints = () => {
  const styles = useStyles();
  const { hudHeight } = React.useContext(DimensionContext);

  return (
    <View style={[styles.container, { width: hudHeight * 1.679, height: hudHeight * 0.5458 }]}>
      <Image
        resizeMode="stretch"
        style={styles.i_label}
        source={{uri: images["t-palms-point"]}}
      />
      <View pointerEvents="none" style={styles.goldBar}>
        <Image
          resizeMode="stretch"
          style={styles.goldBar}
          source={{uri: images["palm-bar"]}}
        />
      </View>
      <CoinCounter baseHeight={hudHeight} />
      <PalmsDropdown />
    </View>
  );
};

export default PalmPoints;
