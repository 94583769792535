import images from "@assets/images";
import SVGDrawer from "@assets/svg/side-drawer";
import React, { useState } from "react";
import { Image, LayoutChangeEvent, Pressable, StyleSheet } from "react-native";

type Props = {
  onPress: () => void;
  item: any;
  source: any;
  index: number;
  isComingSoon: boolean;
  isActive?: boolean;
}

function DrawerItem(props: Props) {
  const [layout, setLayout] = useState<any>({width: 0, height: 0});
  
  const RenderComingSoon = React.useMemo(() => {
    if(props.isComingSoon){
      return <Image source={{uri: images["coming-soon"]}} 
        style={{
          width: layout.width * 0.8, 
          height: layout.height * 0.8, 
          position: "absolute", top: "1%", right: "4%"
        }} 
        resizeMode="stretch" />;
    }

    return null;
  },[props.isComingSoon, layout]);

  return (
    <Pressable onLayout={(e: LayoutChangeEvent) => setLayout(e.nativeEvent.layout)}
      onPress={props.onPress}
      style={[styles.container, props.index === 0 && { marginTop: 0}]} >
      <SVGDrawer name={props.source} width={layout.width} height={layout.height} isActive={props.isActive}/>
      {RenderComingSoon}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {width: "100%", height: "13.5%", marginTop: "4%"},
});

export default DrawerItem;