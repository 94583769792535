const Categories = [
  {
    "name": "MY FAVOURITE",
    "hasGames": true,
    "source": "my-favorite",
    "activeKey": 0,
  },
  {
    "name": "FEATURE",
    "hasGames": true,
    "activeKey": "isFeatured",
    "source": "feature"
  },
  {
    "name": "NEW_GAMES",
    "title": "NEW GAMES",
    "hasGames": true,
    "activeKey": "isNew",
    "source": "new"
  },
  {
    "name": "TOP",
    "hasGames": true,
    "activeKey": "isTop",
    "source": "top"
  },
  {
    "name": "ARCADE",
    "hasGames": true,
    "activeKey": "6,7",
    "source": "arcade"
  },
  {
    "name": "SLOT",
    "hasGames": true,
    "source": "slots",
    "activeKey": 1,
  },
  {
    "name": "INSTANT",
    "hasGames": true,
    "activeKey": "3,5",
    "source": "instant"
  },
];

export default Categories;
