import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedUserCoins } from "@src/ducks/slices/user.slice";
import numeral from "numeral";
import React from "react";
import { Text } from "react-native";
import { CoinContext } from "../../main/CoinProvider";
import useStyles from "./styles.css";

function isNegativeNumber(num: number) {
  return Math.sign(num) === -1;
}

const GoldCoins = ({ baseHeight }: any) => {
  const styles = useStyles();
  const balance  = useAppSelector(selectedUserCoins);
  const [currentGold, setCurrentGold] = React.useState(0);
  const { currency, onAdditionalCurrency } = React.useContext(CoinContext);

  const onAdjustbalance = (amount: number) => {
    const targetBalance = currentGold + amount;

    const isNegative = isNegativeNumber(amount);
    const duration = 900; // 3 seconds total
    const steps = 60; // Number of increments in 3 seconds
    const stepAmount = amount / steps; // Amount to increase per step

    const interval = setInterval(() => {
      setCurrentGold((prev) => {
        if(isNegative){
          if (prev + stepAmount <= targetBalance) {
            clearInterval(interval); // Stop when reaching target balance
            return targetBalance;
          }
        }else{
          if (prev + stepAmount >= targetBalance) {
            clearInterval(interval); // Stop when reaching target balance
            return targetBalance;
          }
        }
        return prev + stepAmount;
      });
    }, duration / steps); // Calculate interval time per step
  };

  React.useEffect(() => {
    setCurrentGold(balance.GOLD + balance["GOLD BONUS"]);
  }, [balance.GOLD, balance["GOLD BONUS"]]);

  React.useEffect(() => {
    if (currency.bg > 0) {
      onAdjustbalance(currency.bg);
      onAdditionalCurrency({ silver: 0, gwz: 0, bg: 0});
    }else if (currency.gwz > 0 || currency.gwz <= -1) {
      onAdjustbalance(currency.gwz);
      onAdditionalCurrency({ silver: 0, gwz: 0, bg: 0});
    }
  }, [currency.bg, currency.gwz]);

  return (
    <Text style={[styles.goldText, { fontSize: baseHeight * 0.17}]}
    >{numeral(currentGold).format("0,000.00")}</Text>
  );
};

export default GoldCoins;
