import CoinsJSON from "@assets/json/coins-increment";
import { useDimensionContext } from "@src/DimensionProvider";
import React from "react";
import Lottie from "react-lottie-player";
import { StyleSheet, View } from "react-native";

const CoinsView = ({ silver }: any) => {
  const [visible, setVisible] = React.useState(false);
  const { baseWidth, maxHeight } = useDimensionContext();

  React.useEffect(() => {
    if (silver > 0) {
      setVisible(true);
    }
  }, [silver]);

  if (!visible) return null;

  return (
    <View style={[styles.container]}>
      <Lottie
        play
        loop={0}
        segments={[50, 100]}
        animationData={CoinsJSON}
        onComplete={() => setVisible(false)}
        style={{ position: "absolute", width: baseWidth, height: "100%", maxHeight: maxHeight }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "transparent",
    zIndex: 200,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default CoinsView;
