import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { ImageButton06 } from "components/button";
import React, { useContext } from "react";
import { Image, Pressable, Text, View } from "react-native";
import { AvatarContext } from "../AvatarProvider";
import useStyles from "./styles.css";
import TierLevelItem from "./tier-level-item";

const TierLevel = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { setShowScreen } = useContext(AvatarContext);
  const { baseWidth } = React.useContext(DimensionContext);

  return (
    <View style={[styles.container]}>
      <View style={[styles.bodyStyle, { height: baseWidth * 0.4583} ]}>
        <Image
          style={styles.i_bg}
          resizeMode="stretch"
          source={{uri: images.bg_transparent}}
        />
        <View style={[styles.v_bg]}>
          <View style={styles.v_center_tier}>
            <Image
              style={[styles.i_tier_rewards]}
              source={{uri: images.tier_rewards}}
              resizeMode="stretch"
            />
            <View style={styles.v_tier_banner}>
              <TierLevelItem
                title="МЕДНО"
                access="Начално ниво"
                baseWidth={baseWidth}
                source={images.Player_level_Cooper}
                bonus="Нито един"
              />
              <Image
                style={[styles.i_tier_arrow]}
                source={{uri: images.tier_arrow1}}
                resizeMode="stretch"
              />
              <TierLevelItem
                title="БРОНЗ"
                baseWidth={baseWidth}
                source={images.Player_level_Bronze}
                access={"Първични игри"}
                bonus="3,000,000"
              />
              <Image
                style={[styles.i_tier_arrow]}
                source={{uri: images.tier_arrow2}}
                resizeMode="stretch"
              />
              <TierLevelItem
                title="СРЕБРО"
                baseWidth={baseWidth}
                source={images.Player_level_Silver}
                access={"Премиум игри"}
                bonus="10,000,000"
              />
              <Image
                style={[styles.i_tier_arrow]}
                source={{uri: images.tier_arrow3}}
                resizeMode="stretch"
              />
              <TierLevelItem
                title="ЗЛАТО"
                baseWidth={baseWidth}
                source={images.Player_level_Gold}
                access={"VIP игри"}
                bonus="17,000,000"
              />
            </View>
            <ImageButton06
              style={[
                styles.btn_back,
                { height: baseWidth * 0.05, width: baseWidth * 0.19 },
              ]}
              source={images["btn-big"]}
              label="НАЗАД КЪМ ПРОФИЛА"
              labelStyle={{fontSize: baseWidth * 0.012}}
              onPress={() => setShowScreen("")}
            />
            <Pressable style={styles.v_more_info}
              onPress={() => navigation.navigate("TierLevel", { viewmore: true})}>
              <Text selectable={false} style={[styles.t_more_info, { fontSize: baseWidth * 0.01}]}>Допълнителна информация</Text>
              <Image
                style={[styles.i_more_info, { width: baseWidth * 0.023, height: baseWidth * 0.023}]}
                source={{uri: images["tier-info"]}}
                resizeMode="stretch"
              />
            </Pressable>
          </View>
        </View>
      </View>
    </View>
  );
};

export default React.memo(TierLevel);
